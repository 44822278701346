import React, { Component } from 'react';
import { MdExpandMore } from 'react-icons/md';
import { FaAsterisk } from 'react-icons/fa';

interface IProps {
  defaultExpanded?: boolean;
  visitorIndex: number;
  validationChanged(key: string, value: boolean): void;
}

interface IState {
  isExpanded: boolean;
  nameValidated: boolean;
  lastNameValidated: boolean;
  emailValidated: boolean;
}

export class VisitorDetail extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.onToggle = this.onToggle.bind(this);
    this.onNameChanged = this.onNameChanged.bind(this);
    this.onLastNameChanged = this.onLastNameChanged.bind(this);
    this.onEmailChanged = this.onEmailChanged.bind(this);

    this.state = {
      isExpanded: this.props.defaultExpanded ?? false,
      nameValidated: false,
      lastNameValidated: false,
      emailValidated: this.props.visitorIndex === 0 ? false : true,
    };
  }

  render() {
    let animClass = this.state.isExpanded ? ' rotated' : '';

    return (
      <div className="visitor-detail">
        <div className="header legend" onClick={this.onToggle}>
          <h4 className="h-5 leading-5">
            Visitor {this.props.visitorIndex + 1}
          </h4>
          <MdExpandMore
            className={'h-5 leading-5 rotable' + animClass}
            size="20"
          />
        </div>
        {this.state.isExpanded && (
          <div className="expanding-con border-t p-2">
            <div className="vd-row">
              <label className="font-bold text-xs">First Name:</label>
              <div className="input-con flex flex-row justify-start items-center">
                <span className="px-2">
                  <FaAsterisk size="6" />
                </span>
                <div className="vr"></div>
                <input
                  className="h-6 leading-6"
                  type="text"
                  minLength={1}
                  required
                  maxLength={500}
                  onChange={(event) => this.onNameChanged(event)}
                />
              </div>
            </div>
            <div className="vd-row">
              <label className="font-bold text-xs">Last Name:</label>
              <div className="input-con flex flex-row justify-start items-center">
                <span className="px-2">
                  <FaAsterisk size="6" />
                </span>
                <div className="vr"></div>
                <input
                  className="h-6 leading-6"
                  type="text"
                  maxLength={500}
                  onChange={this.onLastNameChanged}
                />
              </div>
            </div>
            <div className="vd-row">
              <label className="font-bold text-xs">Email:</label>
              {this.props.visitorIndex === 0 ? (
                <div className="input-con flex flex-row justify-start items-center">
                  <span className="px-2">
                    <FaAsterisk size="6" />
                  </span>
                  <div className="vr"></div>

                  <input
                    className="h-6 leading-6"
                    type="email"
                    maxLength={500}
                    onChange={this.onEmailChanged}
                  />
                </div>
              ) : (
                <div className="input-con flex flex-row justify-start items-center">
                  <input
                    className="h-6 leading-6"
                    type="email"
                    maxLength={500}
                  />
                </div>
              )}
            </div>
            <div className="vd-row">
              <label className="font-bold text-xs">Mobile:</label>
              <div className="input-con flex flex-row justify-start items-center">
                <input
                  datatype=""
                  className="h-6 leading-6"
                  type="text"
                  maxLength={500}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  onToggle() {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  }

  onNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState(
      {
        nameValidated: event.target.value.length > 0,
      },
      this.checkOnValidated
    );
  }

  onLastNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState(
      {
        lastNameValidated: event.target.value.length > 0,
      },
      this.checkOnValidated
    );
  }

  onEmailChanged(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState(
      {
        emailValidated:
          this.props.visitorIndex === 0
            ? event.target.value.length > 0 && event.target.validity.valid
            : true,
      },
      this.checkOnValidated
    );
  }

  checkOnValidated() {
    if (this.props.visitorIndex > 0)
      this.props.validationChanged('vd-' + this.props.visitorIndex, true);
    else {
      if (
        this.state.nameValidated &&
        this.state.lastNameValidated &&
        this.state.emailValidated
      )
        this.props.validationChanged('vd-' + this.props.visitorIndex, true);
      else this.props.validationChanged('vd-' + this.props.visitorIndex, false);
    }
  }
}
