import React, { Component } from 'react';
import { BookingState } from '../Enums/BookingState';
import { Product } from '../System/Models/Product';
import { locator } from '../System/ServiceLocator';
import { ApiService } from '../System/Services/ApiService';
import { Store } from '../System/Stores';
import { DatePicker } from './DatePicker';

interface IProps {}

interface IState {
  products: Product[];
}

export class Index extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.onBookableRequest = this.onBookableRequest.bind(this);

    this.state = {
      products: [],
    };
  }

  async componentDidMount() {
    const apiService = locator(ApiService);

    let products = await apiService.getProducts();

    if (products != null) {
      this.setState({
        products: products,
      });
    }
  }

  render() {
    return (
      <div className="grid grid-cols-1 md:grid-cols-12">
        <div className="col-auto md:col-span-8 px-4 py-2 prologue">
          <h1 className="text-3xl font-bold p-2">
            KSA Pavilion Tourguide Booking
          </h1>
          <article className="bg-white p-4">
            <img
              className="mx-auto"
              src="https://img.rezdy.com/PRODUCT_IMAGE/194530/1788390_lg.jpg"
              alt="KSA Pavilion"
            />
            <p className="py-2">
              Planning to visit our Pavilion? Go ahead and book one of our
              scheduled tours that will enable you to truly experience what the
              Kingdom of Saudi Arabia has to offer. Each tour is for 20 mins
              where one of our experienced guides will take you
            </p>
          </article>
        </div>
        <div className="col-auto md:col-span-4 px-4 py-2 right-bar">
          <header className="p-2 font-bold bar-header">
            Start Booking Now
          </header>
          <DatePicker
            startDate={new Date()}
            onBookableRequest={this.onBookableRequest}
          />
        </div>
      </div>
    );
  }

  onBookableRequest() {
    Store.setBookingState(BookingState.Extra);
  }
}
