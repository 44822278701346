import { action, makeObservable, observable } from 'mobx';
import { BookingState } from '../Enums/BookingState';

class LogicStore {
  bookingState: BookingState = BookingState.Initial;
  visitorCount: number = 1;

  constructor() {
    makeObservable(this, {
      bookingState: observable,
      visitorCount: observable,
      setBookingState: action,
    });
  }

  setBookingState(value: BookingState) {
    this.bookingState = value;
    console.log('BookingStateChanged:', value);
  }
}

export const Store = new LogicStore();
