import { action } from 'mobx';
import React, { Component } from 'react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { Store } from '../System/Stores';

interface IProps {
  startDate: Date;
  onBookableRequest(): void;
}

interface IState {
  selectedDate: Date;
  selectedTime: Date | null;
}

export class DatePicker extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      selectedDate: new Date(),
      selectedTime: null,
    };

    this.onBookNow = this.onBookNow.bind(this);
  }

  getSelectOptions() {
    let options: JSX.Element[] = [];

    for (let i = 0; i < 30; i++) {
      options.push(
        <option key={i} value={i + 1}>
          {(i + 1).toString()}
        </option>
      );
    }

    return options;
  }

  render() {
    let timeView = '00:00';

    if (this.state.selectedTime != null)
      timeView = `${this.state.selectedTime
        ?.getHours()
        .toString()
        .padStart(2, '0')}:${this.state.selectedTime
        ?.getMinutes()
        .toString()
        .padStart(2, '0')}`;

    return (
      <div className="flex flex-col">
        <div className="flex flex-row py-2 pb-3 justify-start items-center">
          <label className="font-bold">
            Enter number of visitors:<span className="text-red-600">*</span>
          </label>
          <select
            className="border-gray-600 border-solid border h-6 ml-2 w-12"
            onChange={(data) => this.onVisitorCountSelected(data)}
          >
            {this.getSelectOptions()}
          </select>
        </div>
        <div className="flex-row font-bold">
          Choose a Preferred Date and Time
          <span className="text-red-600">*</span>
        </div>
        <div className="flex-row">
          <ReactDatePicker
            wrapperClassName="data-picker-wrapper"
            className="date-picker-wrapper"
            selected={this.props.startDate}
            onChange={(date) => this.onDateSelected(date)}
            inline
          />
        </div>
        <div className="flex flex-row justify-end items-center">
          <label className="mx-2 text-black font-bold">
            Preferred Time:<span className="text-red-600">*</span>
          </label>
          <div>
            <ReactDatePicker
              showTimeSelectOnly={true}
              showTimeSelect={true}
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="Time"
              onChange={(date) => this.onTimeSelected(date)}
              onSelect={(date) => {}}
              value={timeView}
            />
          </div>
        </div>
        <div className="flex flex-row py-4 justify-center items-center">
          <button className="button book-btn w-3/4" onClick={this.onBookNow}>
            BOOK NOW
          </button>
        </div>
        <div className="flex-row">
          <span className="font-bold">Please note:</span> After your booking is
          confirmed we will send you a confirmation email.
        </div>
      </div>
    );
  }

  onDateSelected(date: Date | [Date, Date] | null) {
    if (date instanceof Date) {
      console.log('Date: ', date);
      this.setState({ selectedDate: date });
    }
  }

  onTimeSelected(date: Date | [Date, Date] | null) {
    if (date instanceof Date) {
      console.log('Time: ', date);
      this.setState({ selectedTime: date });
    }
  }

  @action
  onVisitorCountSelected(event: React.ChangeEvent<HTMLSelectElement>) {
    let visitors = Number.parseInt(event.target.value);

    Store.visitorCount = visitors;
    console.log('Visitors:', Store.visitorCount);
  }

  onBookNow() {
    this.props.onBookableRequest();
  }
}
