import { AxiosResponse } from 'axios';
import { IApiService } from '../Interfaces/IApiService';
import { IService } from '../Interfaces/IService';
import { MainAxios } from '../MainAxios';
import { Product } from '../Models/Product';

export class ApiService implements IService, IApiService {
  getType(): string {
    return this.constructor.name;
  }

  async getProducts(): Promise<Product[] | null> {
    const axios = MainAxios.GetInstance();

    await axios.get('/products');

    return [];
  }

  async uploadVideo(
    qrCode: string,
    author: string,
    blob: Blob,
    filename: string,
    onUploadProgress: (ev: ProgressEvent) => void
  ): Promise<AxiosResponse> {
    const axios = MainAxios.GetInstance();
    var formData = new FormData();

    formData.append('qrCode', qrCode);
    formData.append('author', author);
    formData.append('resource', blob, filename);

    return axios.post('/uploads/video', formData, {
      timeout: 300 * 1000, // 5 minute upload duration max
      headers: {
        'Content-Type': 'multipart/form-data',
      },

      onUploadProgress: (progressEvent: any) => onUploadProgress(progressEvent),
    });
  }

  async uploadAudio(
    qrCode: string,
    author: string,
    blob: Blob,
    filename: string,
    onUploadProgress: (ev: ProgressEvent) => void
  ): Promise<AxiosResponse> {
    const axios = MainAxios.GetInstance();
    var formData = new FormData();

    formData.append('qrCode', qrCode);
    formData.append('author', author);
    formData.append('resource', blob, filename);

    console.log('Filename:', filename);

    return axios.post('/uploads/audio', formData, {
      timeout: 300 * 1000, // 5 minute upload duration max
      headers: {
        'Content-Type': 'multipart/form-data',
      },

      onUploadProgress: (progressEvent: any) => onUploadProgress(progressEvent),
    });
  }

  async uploadMessage(
    qrCode: string,
    author: string,
    message: string
  ): Promise<AxiosResponse> {
    const axios = MainAxios.GetInstance();

    return axios.post(
      '/uploads/message',
      {
        qrCode: qrCode,
        author: author,
        message: message,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }
}
