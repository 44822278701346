import { action } from 'mobx';
import { observer } from 'mobx-react';
import React, { PureComponent } from 'react';
import { BookingState } from '../Enums/BookingState';
import { Store } from '../System/Stores';
import { VisitorDetail } from './VisitorDetail';

interface IProps {}
interface IState {
  isValidated: boolean;
}

interface KeyValuePair {
  key: string;
  value: boolean;
}

@observer
export class VisitorDetails extends PureComponent<IProps, IState> {
  private visitorElements: JSX.Element[] = [];
  private validationTable: KeyValuePair[] = [];

  constructor(props: IProps) {
    super(props);

    this.onValidationChanged = this.onValidationChanged.bind(this);

    this.state = {
      isValidated: false,
    };

    if (this.visitorElements.length === 0) {
      for (let i = 0; i < Store.visitorCount; i++) {
        this.visitorElements.push(
          <VisitorDetail
            key={'vd-' + i.toString()}
            defaultExpanded={i === 0 ? true : false}
            visitorIndex={i}
            validationChanged={this.onValidationChanged}
          />
        );

        this.validationTable.push({
          key: 'vd-' + i,
          value: i === 0 ? false : true,
        });
      }
    }
  }

  render() {
    return (
      <div className="pt-10 visitor-details">
        <div className="flex flex-col">
          <h1>Booking Details</h1>
          <h2>KSA Pavilion Tourguide Booking</h2>
          <span>Date: 12/06/2021 3:00 PM</span>
        </div>
        {this.visitorElements}
        <div className="flex flex-row justify-center mt-3">
          <button
            className="button finalize-btn"
            disabled={this.state.isValidated ? false : true}
            onClick={this.onClick}
          >
            <span>Book Now</span>
            <span>Please enter all the required fields</span>
          </button>
        </div>
      </div>
    );
  }

  @action
  onClick() {
    Store.bookingState = BookingState.Aftermath;
  }

  onValidationChanged(key: string, value: boolean) {
    let keyPair = this.validationTable.find((q) => q.key === key);

    if (keyPair != null) {
      keyPair.value = value;
    }

    let isValidated: boolean = !this.validationTable.some(
      (q) => q.value === false
    );

    this.setState({
      isValidated: isValidated,
    });
  }
}
